html,
body {
  padding: 0;
  margin: 0;
  min-height: 100%;
  width: 100%;

  font-family: var(--font-inter);
}

html {
  --section-max-width: 1408px;
  --nav-accent-color: #32b300;
  display: flex;
}

body {
  display: grid;
  grid-auto-flow: row;
}

next-route-announcer {
  display: none;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-public-sans);
}

h1 {
  font-weight: 800;
  line-height: 1.5;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  font-size: 2rem;
}

h2 {
  font-weight: 700;
  line-height: 1.25;
  font-size: 1.5rem;
  margin-top: 0.35rem;
  margin-bottom: 1rem;
}

h3 {
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.25;
}

h4 {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25;
  font-family: var(--font-inter);
}

p {
  opacity: 0.9;
  line-height: 1.75;
}

.mapboxgl-control-container {
  display: none;
}
